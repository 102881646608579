import {post} from '@/utils/axios'

// 成绩汇总
export const getResult = params => post('/shida-user-biz/TbPracticeRecordUser/getClassPracticeInfo',params)

export const getResultDetail = params => post('/shida-user-biz/TbPracticeRecordUser/getPracticeInfo',params)

export const getResultById = params => post('/shida-user-biz/TbPracticeRecordUser/getDataByUser',params)

// 社会实践
export const getSocial = params => post('/shida-user-biz/TbPracticeRecordUser/getDataByClass',params)
// 社会实践详情
export const getSocialDetail = params => post('/shida-user-biz/TbPracticeRecordUser/getDataRecordDetail',params)
// 发布
export const publishSocial = params => post('/shida-user-biz/TbPracticeRecord/publishBase',params)
// 获取心得感悟列表
export const getExperience = params => post('/shida-user-biz/TbPracticeRecordUser/getDataByRecord',params)
// 心得感悟详情
export const getExperienceDetail = params => post('/shida-user-biz/TbPracticeRecordUser/getDataPerDetail',params)
// 驳回心得感悟
export const rejectExperience = params => post('/shida-user-biz/TbPracticeRecordUser/updateRejectPer',params)
// 补录发布
export const socialSupplement = params => post('/shida-user-biz/TbPracticeRecord/updateAdditionalUser',params)
// 补录学生列表
export const getSupplementStudent = params => post('/shida-user-biz/TbPracticeRecord/getUnPublishUser',params)
