<template>
  <div class="web-content container">
    <div class="user-nav">
      <Search
        ref="search"
        :term="false"
        :isExperience="true"
        :classlist="classlist"
        @handleQuery="handleQuery"
      />
    </div>
    <div class="main">
      <div>
        <h5>实践主题：{{ paramsData.practiceTitle }}</h5>
        <h5>实践地点：{{ paramsData.practiceAddress }}</h5>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50" fixed>
        </el-table-column>
        <el-table-column prop="userName" label="学生姓名" fixed>
        </el-table-column>
        <el-table-column prop="userNum" label="学籍号" width="180">
        </el-table-column>
        <el-table-column prop="className" label="班级" width="120">
        </el-table-column>
        <el-table-column prop="submitStatus" label="心得感悟">
        </el-table-column>
        <el-table-column prop="verifyStatus" label="心得感悟状态">
        </el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button @click="handleDetail(scope.row)" type="text" size="small"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />

      <div class="back">
        <el-button type="text" @click="backIndex">返回</el-button>
      </div>
    </div>

    <!-- 查看详情 -->
    <el-dialog title="心得感悟" :visible.sync="open" center width="600px">
      <el-form ref="form" :model="form">
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.userNum }}</span>
        </el-form-item>
        <el-form-item label="社会实践主题：">
          <span class="scontent">{{ form.practiceTitle }}</span>
        </el-form-item>
				<el-divider content-position="left">心得感悟</el-divider>
        <div class="scontent" v-html="form.perContext"></div>
        <el-form-item>
          <el-image
            v-for="(item, index) in form.perImg"
            :key="index"
            fit="contain"
            :src="imgSrc + item"
          ></el-image>
        </el-form-item>
        <el-form-item label="基地评分：" v-if="form.practiceType == '0'">
          <el-rate v-model="form.baseScore" :disabled="true" style="margin-top: 10px"></el-rate>
        </el-form-item>
        <el-form-item label="审核状态：">
          <span class="scontent">{{ form.verifyStatus }}</span>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="form.verifyStatus=='已驳回'">
          <span class="scontent" style="color:red">{{ form.rejectCause }}</span>
        </el-form-item>
      </el-form>
      <el-dialog
        width="500px"
        title="驳回原因"
        :visible.sync="innerVisible"
        append-to-body
        center
      >
        <el-form :model="form">
          <el-form-item>
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="驳回原因"
              v-model="form.rejectCause"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="扣分值：">
            <el-select v-model="form.userCreditReduceScore" placeholder="请选择">
              <el-option label="5分" value="5"> </el-option>
              <el-option label="4分" value="4"> </el-option>
              <el-option label="3分" value="3"> </el-option>
              <el-option label="2分" value="2"> </el-option>
              <el-option label="1分" value="1"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>       
        
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">关 闭</el-button>
          <el-button @click="handlereject" type="primary">确 定</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
        <el-button
          @click="innerVisible = true"
          type="primary"
          v-if="form.verifyStatus == '已通过'"
          >驳回</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  getExperience,
  getExperienceDetail,
  rejectExperience,
} from "@/api/teacher/quality/social";
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      tableData: [],
      total: 0,
      queryParams: {
        pageSize: 100,
      },
      paramsData: {},
      classlist: [],
      open: false,
      innerVisible: false,
      form: {},
      rowForm: {},
    };
  },
  components: { Search },
  created() {
    this.paramsData =
      this.$route.params.recordId != undefined
        ? this.$route.params
        : JSON.parse(sessionStorage["params"]);
    this.queryParams.classId = this.paramsData.classId;
    this.queryParams.recordId = this.paramsData.recordId;
    this.getFirst();
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetClassList").then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = "";
        this.$refs.search.getDicBySocial();
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      getExperience(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size
      });
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId;
      this.queryParams.submitStatus = data.submitStatus;
      this.queryParams.verifyStatus = data.verifyStatus;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
    handleDetail(row) {
      if (row.submitStatus == "未写") {
        this.$message.warning("该生还未提交心得感悟");
      } else {
        getExperienceDetail({
          recordId: row.recordId,
          classId: row.classId,
          userId: row.userId,
        }).then((res) => {
          this.form = res.data;
           if (!this.isEmpty(this.form.perImg)) {
            this.form.perImg = this.form.perImg.split(",");
          }
          this.form.baseScore = this.form.baseScore / 2;
          this.open = true;
          this.rowForm = row
        });
      }
    },
    handlereject() {
      rejectExperience({
        recordId: this.rowForm.recordId,
        classId: this.rowForm.classId,
        userId: this.rowForm.userId,
        rejectCause: this.form.rejectCause,
        userCreditReduceScore: this.form.userCreditReduceScore
      }).then(res => {
        this.$message.success('驳回成功')
        this.innerVisible = false
        this.open = false
        this.getList()
      })
    },
    backIndex() {
      this.$router.push({ name: "social", params: { activeName: "1" } });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
.container {
  .user-nav {
    padding: 10px 15px;
  }
  .main {
    h5 {
      margin: 7px 0;
    }
  }
}
</style>